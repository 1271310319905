import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Embla } from '@mantine/carousel';


interface CarouselState {
  scrollProgress: number;
  activeSlideIndex: number;
}

const initialState: CarouselState = {
  scrollProgress: 0,
  activeSlideIndex: 0,
};

const arcanisStrategySlideControl = createSlice({
  name: 'emblaCarousel',
  initialState,
  reducers: {
    setScrollProgress: (state, action) => {
      state.scrollProgress = action.payload;
    },
    setActiveSlideIndex: (state, action) => {
      state.activeSlideIndex = action.payload;
    },
  }
});

export const { setScrollProgress, setActiveSlideIndex } = arcanisStrategySlideControl.actions;
export const arcanisStrategySlideControlReducer =  arcanisStrategySlideControl.reducer;