import { createSlice } from '@reduxjs/toolkit';
import { SelectedPage } from '@/types/types';

interface MenuLinksState {
  selectedPage: SelectedPage;
  isTopOfPage: boolean;
}

const initialState: MenuLinksState = {
  selectedPage: SelectedPage.Home,
  isTopOfPage: true,
};

const menuLinksSlice = createSlice({
  name: 'menuLink',
  initialState,
  reducers: {
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
    setIsTopOfPage(state, action) {
      state.isTopOfPage = action.payload;
    },
  },
});

export const { setSelectedPage, setIsTopOfPage } = menuLinksSlice.actions;
export const menuLinksReducer = menuLinksSlice.reducer;
