import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  consentGiven: false,
};

const userConsentSlice = createSlice({
  name: 'userConsent',
  initialState,
  reducers: {
    giveConsent: (state, action) => {
      state.consentGiven = action.payload;
    },
  },
});

export const { giveConsent } = userConsentSlice.actions;

export const userConsentSliceReducer = userConsentSlice.reducer;
  