import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  activeIndex: null,  
};

export const indexHoldersModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.activeIndex = action.payload; 
    },
    closeModal: state => {
      state.isOpen = false;
      state.activeIndex = null; 
    },
  },
});

export const { openModal, closeModal } = indexHoldersModalSlice.actions;

export const indexHoldersModalReducer = indexHoldersModalSlice.reducer;
