import { createSlice } from '@reduxjs/toolkit';

export const activeChartSerie = createSlice({
  name: 'activeIndex',
  initialState: {
    value: '',
  },
  reducers: {
    setActiveIndex: (state, action) => {
      state.value = action.payload;
    },
    clearActiveIndex: (state) => {
      state.value = '';
    },
  },
});

export const { setActiveIndex, clearActiveIndex } = activeChartSerie.actions;

export const activeChartSerieReducer = activeChartSerie.reducer;