import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeYear: 2023,
  activeQuarter: 3,  
};

export const indexHoldersQuarterTabs = createSlice({
  name: 'selectedQuarter',
  initialState,
  reducers: {
    setSelectedYear: (state, action) => {
      state.activeYear = action.payload; 
    },
    setSelectedQuarter: (state, action) => {
      state.activeQuarter = action.payload; 
    },
  },
});

export const { setSelectedQuarter, setSelectedYear } = indexHoldersQuarterTabs.actions;

export const indexHoldersQuarterTabsReducer = indexHoldersQuarterTabs.reducer;
